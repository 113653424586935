<template>
  <v-app>
    <v-row justify="start" align="start" class="mt-4">
      <v-col cols="11" class="mx-auto">
        <v-col v-if="errorMessage">
          <p class="red--text h3">{{ errorMessage }}</p>
          <p>
            Please ensure to provide a valid ID for an existing batch in the URL
          </p>
          <small>Click the button below to navigate back to batches list</small>
          <br />
          <v-btn
            :to="{name: 'view-batches'}"
            color="green darken-4 white--text"
            class="mr-5 mt-5"
            medium
          >
            <small class="mx-8">Back</small>
          </v-btn>
        </v-col>
        <v-col v-else>
          <h5 class="display-1 mb-3">Edit Batch</h5>
          <span class="d-block">Batch Name: {{ batch.name }}</span>
          <span class="d-block">Batch ID: {{ batch.batch_id }}</span>
          <span class="d-block">Created At: {{ batch.date_created }}</span>
          <span class="d-block">Created By: {{ batch.created_by }}</span>
          <!-- Beginning of Form -->
          <div class="my-5 py-5">
            <v-form ref="form" @submit.prevent="handleSubmit">
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    id="name"
                    name="name"
                    label="Batch Name"
                    color="green darken-4"
                    v-model="name"
                    :rules="nameRule"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    id="id"
                    name="name"
                    label="Batch ID (readonly)"
                    color="green darken-4"
                    v-model="name"
                    :rules="batchIDRule"
                    outlined
                    readonly
                    required
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    id="created_by"
                    name="name"
                    label="Created by (readonly)"
                    placeholder="Full Name"
                    color="green darken-4"
                    v-model="createdBy"
                    :rules="createdByRule"
                    readonly
                    outlined
                    required
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex" v-if="!isLoading">
                  <v-btn
                    type="submit"
                    color="green darken-4 white--text"
                    class="mr-5"
                    medium
                  >
                    <span class="mx-8">Update</span>
                  </v-btn>
                  <!-- server error -->
                  <p
                    v-if="error"
                    class="red--text align-content-baseline mt-2 ml-4"
                  >
                    Failed to update batch with <i>Name</i>:
                    <strong>{{ name }}</strong
                    >, <i>ID</i>: <strong>{{ batchID }}</strong> and
                    <i>Author</i>:
                    <strong>{{ createdBy }}</strong>
                  </p>
                </v-col>
                <v-col cols="12" v-else class="text-center m-3">
                  <!-- loading indicator -->
                  <v-progress-circular
                    :width="4"
                    :size="40"
                    color="green darken-4"
                    indeterminate
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-form>
            <!-- End of the form -->
          </div>
        </v-col>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "edit-batch",
  data() {
    return {
      batch: null,
      name: "",
      nameRule: [(v) => !!v || "Batch name is required"],
      batchID: "",
      batchIDRule: [(v) => !!v || "Batch ID is required"],
      createdBy: "",
      createdByRule: [(v) => !!v || "Batch Author name is required"],
      error: false,
      errorMessage: "",
    };
  },
  watch: {
    name() {
      this.error = false;
    },
    id() {
      this.error = false;
    },
  },
  methods: {
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        this.batchID = this.name;
        try {
          const {
            name,
            createdBy: created_by,
            batchID: batch_id,
            batch,
          } = this;
          // returns created batch
          const response = await this.updateBatch({
            data: { id: batch.id, name, created_by, batch_id },
          });

          if (response["statusText"] === "OK") {
            // Redirect if batch was successfully updated
            this.$router.push({
              name: "view-batch-claim-list",
              params: { batch_id: batch.id },
            });
          }
        } catch (error) {
          this.error = true;
        }
      }
    },
    ...mapActions({ updateBatch: "claimModule/updateBatch" }),
  },
  computed: {
    ...mapGetters({
      user: "user",
      isLoading: "claimModule/isLoading",
      getBatchByID: "claimModule/getBatchByID",
    }),
  },
  created() {
    this.createdBy = `${this.user.user_firstname} ${this.user.user_lastname}`;
    this.batch = this.getBatchByID(this.$route.params.batch_id);
    // show error message if batch_id in url params isn't found in list of batches
    if (!this.batch) {
      this.errorMessage = `Oops, no batch found matching ID: ${this.$route.params.batch_id}`;
      return;
    }
    // populate data fields in form
    const { name, created_by, batch_id } = this.batch;
    this.name = name;
    this.createdBy = created_by;
    this.batchID = batch_id;
  },
};
</script>
